import React, { useEffect, useState } from 'react';
import { RiArrowLeftDoubleFill } from "react-icons/ri";
import { RiArrowRightDoubleFill } from "react-icons/ri";
import { motion } from "framer-motion";
import image2 from "../../Assets/he1.png"
import image3 from "../../Assets/he2.png"
import image4 from "../../Assets/he3.png"
import { FaArrowRight } from "react-icons/fa6";
import { IoArrowUndo } from "react-icons/io5";
import { IoArrowRedoSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

const Slider = () => {
    const navigate = useNavigate();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [leftButtonHover, setLeftButtonHover] = useState(false);
    const [rightButtonHover, setRightButtonHover] = useState(false);

    const leftHandleHoverStart = (side) => {
        setLeftButtonHover(true);
    };

    const leftHandleHoverEnd = (side) => {
        setLeftButtonHover(false);
    };

    const rightHandleHoverStart = (side) => {
        setRightButtonHover(true);
    };

    const rightHandleHoverEnd = (side) => {
        setRightButtonHover(false);
    };

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    const goToSlide = (index) => {
        setCurrentIndex(index);
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            nextSlide();
        }, 5000);

        return () => clearInterval(intervalId);
    }, [currentIndex]);

    const images = [
        {
            src: image2,
            header: "Impeccable Fashion",
            text: "Where elegance meets creativity, our curated collection offers timeless styles with a contemporary edge. Elevate your wardrobe and express your individuality effortlessly with us."
        },
        {
            src: image4,
            header: "Exemplary Grooming",
            text: "Elevating your style with precision and flair. Experience sophistication in every detail. From tailored cuts to luxurious treatments, indulge in the epitome of refinement."
        },
        {
            src: image3,
            header: "Makeup Aficionado",
            text: "Where beauty becomes an art, our salon is your canvas. Discover expert techniques and premium products meticulously curated to enhance your unique features and create your signature look effortlessly."
        }
    ];

    return (
        <React.Fragment>
            <div className="relative w-full md:h-[500px] h-[400px] overflow-hidden">
                <div
                    className="flex transition-transform duration-300 ease-in-out transform"
                    style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                >
                    {images.map((image, index) => (
                        <motion.div
                            key={index}
                            className={`w-full flex-shrink-0 relative ${index === currentIndex ? 'opacity-100' : 'opacity-0'}`}
                            initial={{ x: "-100%" }}
                            animate={{ x: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            <img
                                src={image.src}
                                alt={`Slide ${index + 1}`}
                                className="w-full md:h-[500px] h-[400px] object-cover"
                            />
                            {index === currentIndex && (
                                <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col p-10">
                                    <motion.h2
                                        initial={{ opacity: 0, y: -50 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.9 }}
                                        className="md:text-[60px] text-[30px] font-extrabold tracking-wider text-secondaryThemeClor">
                                        {image.header}
                                    </motion.h2>

                                    <motion.p
                                        initial={{ opacity: 0, y: 50 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 1.2 }}
                                        className='text-xl md:text-5xl font-bold text-themeBlack tracking-wide my-6'>
                                        Affordable Prices
                                    </motion.p>

                                    <motion.p
                                        initial={{ opacity: 0, y: 50 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 1.2 }}
                                        className='text-xs md:text-[20px] md:leading-8 tracking-wide w-1/2 text-gray-400 font-semibold'>
                                        {image.text}
                                    </motion.p>

                                    <div className='hidden lg:flex justify-start items-center gap-6 w-1/2 mt-12'>
                                        <button
                                            onClick={() => navigate("/contact")}
                                            data-aos="zoom-in"
                                            className='text-[20px] bg-secondaryThemeClor hover:bg-white hover:border-2 hover:border-secondaryThemeClor rounded-lg py-2 px-4 tracking-wide w-1/3 text-themeWhite hover:text-themeBlack'>
                                            Appointment
                                        </button>

                                        <button
                                            onClick={() => navigate("/contact")}
                                            data-aos="zoom-out-up"
                                            className='text-[20px] bg-white hover:bg-secondaryThemeClor border-2 border-secondaryThemeClor rounded-lg py-2 px-4 tracking-wide w-1/3 text-themeBlack hover:text-themeWhite'>
                                            Connect Us
                                        </button>

                                        {/* <motion.button
                                            initial={{ opacity: 0, y: 50 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{ duration: 1.2 }}
                                            className='text-[20px] bg-secondaryThemeClor hover:bg-white hover:border-2 hover:border-secondaryThemeClor rounded-lg py-2 px-4 tracking-wide w-1/3 text-themeWhite hover:text-themeBlack'>
                                            Appointment
                                        </motion.button>

                                        <motion.button
                                            initial={{ opacity: 0, y: 50 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{ duration: 1.2 }}
                                            className='text-[20px] bg-white hover:bg-secondaryThemeClor border-2 border-secondaryThemeClor rounded-lg py-2 px-4 tracking-wide w-1/3 text-themeBlack hover:text-themeWhite'>
                                            Connect Us
                                        </motion.button> */}
                                    </div>
                                </div>
                            )}
                        </motion.div>
                    ))}
                </div>

                <motion.button
                    onClick={prevSlide}
                    onMouseEnter={leftHandleHoverStart}
                    onMouseLeave={leftHandleHoverEnd}
                    className="absolute top-1/2 left-0 transform -translate-y-1/2 focus:outline-none text-themeBlack hover:text-secondaryThemeClor font-bold cursor-pointer"
                    whileHover={{ scale: 1.1 }}
                >
                    <motion.div
                        initial={{ x: 0 }}
                        animate={{ x: leftButtonHover ? 0 : 10 }}
                        transition={{ duration: 0.3 }}
                    >
                        <IoArrowUndo size={22} />
                    </motion.div>
                </motion.button>

                <motion.button
                    onClick={prevSlide}
                    onMouseEnter={rightHandleHoverStart}
                    onMouseLeave={rightHandleHoverEnd}
                    className="absolute top-1/2 right-2 transform -translate-y-1/2 focus:outline-none text-themeBlack hover:text-secondaryThemeClor font-bold cursor-pointer"
                    whileHover={{ scale: 1.1 }}
                >
                    <motion.div
                        initial={{ x: 0 }}
                        animate={{ x: rightButtonHover ? 10 : 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <IoArrowRedoSharp size={22} />
                    </motion.div>
                </motion.button>

                <div className="absolute bottom-4 left-0 right-0 flex justify-center space-x-2">
                    {images.map((_, index) => (
                        <button
                            key={index}
                            onClick={() => goToSlide(index)}
                            className={`h-4 w-4  rounded-full focus:outline-none ${currentIndex === index ? 'bg-secondaryThemeClor' : 'bg-themeBlack'}`}
                        />
                    ))}
                </div>
            </div>
        </React.Fragment >
    );
}

export default Slider;
