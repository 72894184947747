import React, { useState, useEffect } from 'react';
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate, useParams } from 'react-router-dom';
import servicesData from "../../all_services.json";
import ServiceCard from '../../Components/Common/ServicesCard';
import { FaAlignRight } from 'react-icons/fa';

const Services = () => {
    const { serviceName } = useParams();
    const navigate = useNavigate();
    const [serviceValue, setServiceValue] = useState(serviceName || "male_services");
    const [categoryData, setCategoryData] = useState();
    const [categoryName, setCategoryName] = useState("");
    const [showSideIcons, setShowSideIcons] = useState(false);

    const filterService = Object.keys(servicesData)?.find(key => key === serviceValue);
    const selectedServices = servicesData[filterService] || [];
    useEffect(() => {
        if (serviceName) {
            setServiceValue(serviceName);
        }
    }, [serviceName]);
    useEffect(() => {
        setCategoryName(selectedServices?.[0]?.category)
        setCategoryData(selectedServices?.[0]?.services)
    }, [selectedServices])

    return (
        <div>
            <div className='text-6xl content-center text-center font-semibold bg-[#f8f8f8] min-h-[200px] tracking-wider lg:px-20 md:px-10 px-5 capitalize'>
                {filterService === "male_services" ? "Male Services" : "Female Services"}
            </div>

            <div className='flex gap-4 py-4 lg:px-20 md:px-10 px-5 border-gray-300 border-b '>
                <span>
                    Home
                </span>

                <span>
                    <IoIosArrowForward size={20} color='#FFD700' />
                </span>

                <span>
                    Services
                </span>

                <span>
                    <IoIosArrowForward size={20} color='#FFD700' />
                </span>

                <span>
                    {filterService === "male_services" ? "Male Services" : "Female Services"}
                </span>
            </div>

            <div className='px-20 flex mt-10 mb-4 relative'>
                <div className="block md:hidden">
                    <FaAlignRight
                        onClick={() => setShowSideIcons(prev => !prev)}
                        size={25} className="text-secondaryThemeClor" />
                </div>
                <div className={`z-10 absolute top-0 w-full bg-[#f8f8f8] rounded-md left-0 transition-all transform duraion-300 origin-top ${showSideIcons ? 'visible opacity-100 scale-y-100' : 'scale-y-0 invisible opacity-0'}`}>
                    <div className='flex flex-col gap-2  md:px-10 px-5'>
                        <div className="flex justify-end p-2">
                            <button onClick={() => setShowSideIcons(prev => !prev)}
                                className="bg-secondaryThemeClor px-4 py-2 rounded-md text-themeBlack">
                                Close
                            </button>
                        </div>
                        {selectedServices?.map((ele, i) =>
                            <div
                                key={i}
                                onClick={() => {
                                    setCategoryName(ele?.category)
                                    setCategoryData(ele?.services)
                                    setShowSideIcons(false)
                                }}
                                className={`flex justify-between items-center ${categoryName === ele?.category ? "bg-primaryThemeColor" : "bg-[#f8f8f8]"}  px-3 py-2 cursor-pointer rounded-md gap-2`}>
                                <span>{ele?.category}</span>
                                <span><IoIosArrowForward size={10} /></span>
                            </div>
                        )}
                    </div>
                </div>
                <div className='min-w-[300px] hidden md:flex flex-col gap-2'>
                    <div className='flex flex-col gap-2  md:px-10 px-5'>
                        {selectedServices?.map((ele, i) =>
                            <div
                                key={i}
                                onClick={() => {
                                    setCategoryName(ele?.category)
                                    setCategoryData(ele?.services)
                                }}
                                className={`flex justify-between items-center ${categoryName === ele?.category ? "bg-primaryThemeColor" : "bg-[#f8f8f8]"}  px-3 py-2 cursor-pointer rounded-md gap-2`}>
                                <span>{ele?.category}</span>
                                <span><IoIosArrowForward size={10} /></span>
                            </div>
                        )}
                    </div>
                </div>

                <div>
                    <div className="w-full pl-5 lg:pl-2 mb-2 tracking-wider text-secondaryThemeClor">
                        <h1 className="text-xl lg:text-2xl font-extrabold">
                            Services
                        </h1>
                    </div>

                    <div className='flex flex-wrap justify-center items-center gap-4'>
                        {categoryData?.map((v, i) => (
                            <ServiceCard
                                type='services'
                                value={v}
                                key={i}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;
