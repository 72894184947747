import React from 'react'
import img1 from "../../Assets/ing-1.jpg"
import img2 from "../../Assets/img-2.jpg"
import img3 from "../../Assets/img-3.jpg"
import { TbMathGreater } from "react-icons/tb";

const PortfolioImages = () => {
    const imgArr = [
        {
            id: 1,
            url: img1,
            title: "A Spash Of Colour",
            btnText: "READ MORE"
        },
        {
            id: 1,
            url: img2,
            title: "Wedding hair style",
            btnText: "READ MORE"
        },
        {
            id: 1,
            url: img3,
            title: "Short and glamorous",
            btnText: "READ MORE"
        }
    ]
    return (
        <React.Fragment>
            <div className="bg-themeBlack w-full py-10 px-4 sm:px-10 md:px-20 lg:px-40">
                <div className="flex flex-col sm:flex-row justify-between pt-10">
                    <div className="text-white text-xl tracking-wider opacity-80 mb-4 sm:mb-0">
                        OUR PORTFOLIO
                    </div>
                    <div className="tracking-wider text-white opacity-80 text-sm flex flex-wrap gap-4 justify-center sm:justify-start">
                        <span>All Projects</span>
                        <TbMathGreater size={16} color='#fffd82' />
                        <span>Hair Style</span>
                        <TbMathGreater size={16} color='#fffd82' />
                        <span>Make Up</span>
                        <TbMathGreater size={16} color='#fffd82' />
                        <span>Nails</span>
                        <TbMathGreater size={16} color='#fffd82' />
                        <span>Hair Product</span>
                    </div>
                </div>
                <div className="justify-center py-10 flex flex-wrap gap-6">
                    {imgArr?.map((v, i) => (
                        <div
                            key={i}
                            style={{ backgroundImage: `url(${v?.url})` }}
                            className="flex flex-col bg-cover relative w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 h-60 justify-end group transition-all duration-300"
                        >
                            <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-60 transition-opacity duration-300"></div>
                            <div className="absolute bottom-5 left-5 flex flex-col gap-2 z-10">
                                <span className="text-white text-lg tracking-wide hidden group-hover:block">
                                    {v?.title}
                                </span>
                                <div className="hidden group-hover:block transition linear duration-500">
                                    <button className="text-md tracking-wide group-hover:text-opacity-100 bg-btnHoverColor hover:bg-yellow-500 px-4 content-center py-2 rounded-md text-white duration-300">
                                        {v?.btnText}
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>


            {/* <div className='bg-themeBlack w-full py-10 px-40'>
                <div className='flex justify-between  pt-10'>
                    <div className='text-white text-xl tracking-wider  opacity-80'>
                        OUR PORTFOLIO
                    </div>
                    <div className='text-white opacity-80 text-sm flex gap-4'>
                        <span>
                            All Projects
                        </span>
                        <TbMathGreater size={15} className='opacity-70' />
                        <span>
                            Hair Style
                        </span>
                        <TbMathGreater size={15} className='opacity-70' />
                        <span>
                            Make Up
                        </span>
                        <TbMathGreater size={15} className='opacity-70' />
                        <span>
                            Nails
                        </span>
                        <TbMathGreater size={15} className='opacity-70' />
                        <span>
                            Hair Product
                        </span>
                    </div>
                </div>
                <div className=' justify-center py-10 flex gap-6 '>
                    {imgArr?.map((v, i) => {
                        return (
                            <div
                                key={i}
                                style={{ backgroundImage: `url(${v?.url})` }}
                                className='flex flex-col bg-cover relative w-96 py-6 px-4 h-60 justify-end group transition-all duration-300'
                            >
                                <div className='absolute inset-0 bg-black opacity-0 group-hover:opacity-60 transition-opacity duration-300'></div>
                                <div className='absolute bottom-5 left-5 flex flex-col gap-2 z-10'>
                                    <span className='text-white text-lg tracking-wide hidden group-hover:block'>
                                        {v?.title}
                                    </span>
                                    <div className='hidden group-hover:block transition linear duration-500'>
                                        <button className='text-md tracking-wide group-hover:text-opacity-100 bg-btnHoverColor hover:bg-yellow-500 px-4 content-center py-2 rounded-md text-white duration-300'>
                                            {v?.btnText}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div> */}
        </React.Fragment>
    )
}

export default PortfolioImages