import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FaBars } from "react-icons/fa";
import { FaHome } from "react-icons/fa";
import { MdPermContactCalendar } from "react-icons/md";
import { useState } from 'react';
import { FaFemale } from "react-icons/fa";
import { FaMale } from "react-icons/fa";
import { useEffect } from 'react';
import { IoMdClose } from "react-icons/io";
import { TbInfoSquareFilled } from "react-icons/tb";
import { useNavigate } from 'react-router-dom';
import { MdCleaningServices } from "react-icons/md";
import { FaBook } from "react-icons/fa";
import { MdOutlineMenuBook } from "react-icons/md";
import { IoBookSharp } from "react-icons/io5";
import { FaBookReader } from "react-icons/fa";
import { IoIosBookmarks } from "react-icons/io";

const icons = {
    "Home": <FaHome size={24} className='text-secondaryThemeClor' />,
    "About": <TbInfoSquareFilled size={24} className='text-secondaryThemeClor' />,
    "Contact": <MdPermContactCalendar size={24} className='text-secondaryThemeClor' />,
    "Male Services": <FaMale size={24} className='text-secondaryThemeClor' />,
    "Female Services": <FaFemale size={24} className='text-secondaryThemeClor' />,
    "Services": <MdCleaningServices size={24} className='text-secondaryThemeClor' />,
    "Courses": <FaBook size={24} className='text-secondaryThemeClor' />,
    "Master Course": <MdOutlineMenuBook size={24} className='text-secondaryThemeClor' />,
    "Makeup Course": <IoBookSharp size={24} className='text-secondaryThemeClor' />,
    "Diploma Course": <FaBookReader size={24} className='text-secondaryThemeClor' />,
    "Basic Course": <IoIosBookmarks size={24} className='text-secondaryThemeClor' />,
};

const SideBar = () => {
    const [state, setState] = useState(false);
    const [isMobile, setIsMobile] = useState(true);
    const [servicesOpen, setServicesOpen] = useState(false);
    const [courseOpen, setCourseOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState(open);
    };

    const [activeItem, setActiveItem] = useState('');

    const handleListItemClick = (text) => {
        setActiveItem(text);
    };

    const list = () => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <div className='flex justify-end my-2 px-2'>
                <IoMdClose size={20} className='text-secondaryThemeClor' />
            </div>
            <List>
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={() => { handleListItemClick("Home"); navigate("/") }}
                        className={activeItem === "Home" ? 'bg-secondaryThemeClor text-white' : ''}>
                        <ListItemIcon>
                            {icons["Home"]}
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={() => { handleListItemClick("about"); navigate("/about") }}
                        className={activeItem === "about" ? 'bg-secondaryThemeClor text-white' : ''}>
                        <ListItemIcon>
                            {icons["About"]}
                        </ListItemIcon>
                        <ListItemText primary="About" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={() => { handleListItemClick("contact"); navigate("/contact") }}
                        className={activeItem === "contact" ? 'bg-secondaryThemeClor text-white' : ''}>
                        <ListItemIcon>
                            {icons["Contact"]}
                        </ListItemIcon>
                        <ListItemText primary="Contact" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={(e) => { e.stopPropagation(); setServicesOpen(!servicesOpen); }}
                        className={activeItem === "Services" ? 'bg-secondaryThemeClor text-white' : ''}>
                        <ListItemIcon>
                            {icons["Services"]}
                        </ListItemIcon>
                        <ListItemText primary="Services" />
                    </ListItemButton>
                </ListItem>
                {servicesOpen && <ListItem className='flex flex-col'>
                    <div className={`transition-all duration-300 ease-in-out ${servicesOpen ? 'max-h-40' : 'max-h-0 overflow-hidden'}`}>
                        <ListItem disablePadding>
                            <ListItemButton
                                onClick={() => { handleListItemClick("Male Services"); navigate("/services/male_services") }}
                                className={activeItem === "Male Services" ? 'bg-secondaryThemeClor text-white' : ''}>
                                <ListItemIcon>
                                    {icons["Male Services"]}
                                </ListItemIcon>
                                <ListItemText primary="Male Services" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton
                                onClick={() => { handleListItemClick("Female Services"); navigate("/services/female_services") }}
                                className={activeItem === "Female Services" ? 'bg-secondaryThemeClor text-white' : ''}>
                                <ListItemIcon>
                                    {icons["Female Services"]}
                                </ListItemIcon>
                                <ListItemText primary="Female Services" />
                            </ListItemButton>
                        </ListItem>
                    </div>
                </ListItem>}
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={(e) => { e.stopPropagation(); setCourseOpen(!courseOpen); }}
                        className={activeItem === "Courses" ? 'bg-secondaryThemeClor text-white' : ''}>
                        <ListItemIcon>
                            {icons["Courses"]}
                        </ListItemIcon>
                        <ListItemText primary="Courses" />
                    </ListItemButton>
                </ListItem>
                <ListItem className='flex flex-col'>
                    <div className={`transition-all duration-300 ease-in-out ${courseOpen ? 'max-h-40' : 'max-h-0 overflow-hidden'}`}>
                        <ListItem disablePadding>
                            <ListItemButton
                                onClick={() => { handleListItemClick("Basic Course"); navigate("/courses/Basic Course") }}
                                className={activeItem === "Basic Course" ? 'bg-secondaryThemeClor text-white' : ''}>
                                <ListItemIcon>
                                    {icons["Basic Course"]}
                                </ListItemIcon>
                                <ListItemText primary="Basic Course" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton
                                onClick={() => { handleListItemClick("Diploma Course"); navigate("/courses/Diploma Course") }}
                                className={activeItem === "Diploma Course" ? 'bg-secondaryThemeClor text-white' : ''}>
                                <ListItemIcon>
                                    {icons["Diploma Course"]}
                                </ListItemIcon>
                                <ListItemText primary="Diploma Course" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton
                                onClick={() => { handleListItemClick("Makeup Course"); navigate("/courses/Makeup detailed course") }}
                                className={activeItem === "Makeup Course" ? 'bg-secondaryThemeClor text-white' : ''}>
                                <ListItemIcon>
                                    {icons["Makeup Course"]}
                                </ListItemIcon>
                                <ListItemText primary="Makeup Course" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton
                                onClick={() => { handleListItemClick("Master Course"); navigate("/courses/Master Course") }}
                                className={activeItem === "Master Course" ? 'bg-secondaryThemeClor text-white' : ''}>
                                <ListItemIcon>
                                    {icons["Master Course"]}
                                </ListItemIcon>
                                <ListItemText primary="Master Course" />
                            </ListItemButton>
                        </ListItem>
                    </div>
                </ListItem>
            </List>
        </Box>
    );

    if (!isMobile) {
        return null;
    }

    return (
        <div>
            <Button onClick={toggleDrawer(true)}><FaBars size={26} className='text-themeWhite' /></Button>
            <SwipeableDrawer
                anchor="right"
                open={state}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                {list()}
            </SwipeableDrawer>
        </div>
    );
}

export default SideBar;
