import React from 'react'
import img4 from '../../Assets/img4.jpg'
import img5 from '../../Assets/img-5.jpg'
import { useEffect } from 'react'
import Aos from 'aos'

const CosmeticsInfo = () => {
    useEffect(() => {
        Aos.init({ duration: 500 })
    }, [])
    return (
        <React.Fragment>
            <div
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
                className="flex flex-col md:flex-row gap-2 py-10 px-4 sm:px-10 md:px-20"
            >
                <div className="flex justify-center md:justify-end w-full md:w-[55%]">
                    <img src={img4} alt="Cosmetics" className="w-full h-auto md:w-auto md:h-auto" />
                </div>
                <div
                    data-aos="zoom-in-down"
                    className="flex-1 flex-shrink-0 flex flex-col gap-4 px-4 sm:px-10 justify-center items-start"
                >
                    <div className="text-2xl tracking-wider text-themeBlack max-w-full font-semibold">
                        Superior Quality Ingredients
                    </div>
                    <div className="text-justify text-md text-themeBlack tracking-wide">
                        Each product in our range is crafted using the finest ingredients, offering exceptional performance and skin compatibility. We prioritize the health and beauty of your skin, ensuring our formulas are free from harmful chemicals, cruelty-free, and dermatologically tested.
                    </div>
                    <div className="text-2xl tracking-wider text-themeBlack max-w-full font-semibold">
                        Expertly Crafted Formulations
                    </div>
                    <div className="text-justify text-md text-themeBlack tracking-wide">
                        Our cosmetics are developed by industry-leading experts and renowned chemists who understand the intricate needs of professional makeup application. This expertise guarantees products that not only enhance your beauty but also nourish and protect your skin.
                    </div>
                </div>
            </div>

            <div
                className="w-full h-[400px] md:h-[500px] bg-cover flex items-center px-4 sm:px-10 md:px-32"
                style={{ backgroundImage: `url(${img5})` }}
            >
                <div
                    data-aos="zoom-in"
                    className="text-white flex flex-col gap-2 w-full md:w-auto">
                    <span className="text-3xl font-semibold text-white tracking-wider">
                        BEAUTY IS IN THE EYE OF THE BEHOLDER
                    </span>
                    <span className="text-md text-white content-center tracking-wider">
                        WITH US, BEAUTY REACHES ALL EYES
                    </span>
                    <span className="tracking-wide text-sm text-white content-center mt-4 w-full md:w-[60%] text-justify leading-6">
                        At our core, we believe that true beauty is unique and personal. "Beauty is in the Eye of the Beholder" celebrates individuality and diversity. Our inclusive range of high-quality, skin-friendly products enhances your natural beauty and helps you express your true self. From versatile eye palettes to hydrating lip balms, each product is designed with you in mind. Embrace and enhance what makes you unique with our cruelty-free, eco-friendly products. Celebrate your beauty with confidence and grace.
                    </span>
                    <div>
                        <button className="bg-primaryThemeColor px-4 py-2 mt-4 text-gray-800 rounded-md hover:text-gray-500 font-bold tracking-wide">
                            Jyoti's Professional
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default CosmeticsInfo