import servicesData from "../../all_services.json";
import React, { useState, useEffect } from 'react';
import { IoIosArrowForward } from "react-icons/io";
import { useParams } from 'react-router-dom';
import ServiceCard from "../../Components/Common/ServicesCard";
import { FaAlignRight } from "react-icons/fa";

const Courses = () => {
    const { courseName } = useParams();
    const [courseType, setCourseType] = useState(courseName || "male_services");
    const [categoryData, setCategoryData] = useState([]);
    const [categoryName, setCategoryName] = useState("");
    const [showSideIcons, setShowSideIcons] = useState(false);

    const filteredCourse = servicesData?.all_courses_type?.find(ele => ele?.type === courseType);
    const selectedServices = filteredCourse?.Courses || [];

    useEffect(() => {
        if (courseName) {
            setCourseType(courseName);
        }
    }, [courseName]);

    useEffect(() => {
        setCategoryName(filteredCourse?.Courses?.[0]?.category);
        setCategoryData(selectedServices?.[0]?.services);
    }, [selectedServices]);

    return (
        <React.Fragment>
            <div className='text-6xl content-center text-center font-semibold bg-[#f8f8f8] min-h-[200px] tracking-wider px-10 md:px-20 capitalize'>
                {filteredCourse?.type}
            </div>
            <div className='flex gap-4 py-4 px-10 md:px-20 border-gray-300 border-b '>
                <span>Home</span>
                <span><IoIosArrowForward size={20} color='#FFD700' /></span>
                <span>Courses</span>
                <span><IoIosArrowForward size={20} color='#FFD700' /></span>
                <span>{filteredCourse?.type}</span>
            </div>

            <div className='px-10 md:px-20 flex mt-10 mb-4 relative'>
                <div className="block md:hidden">
                    <FaAlignRight
                        onClick={() => setShowSideIcons(prev => !prev)}
                        size={25} className="text-secondaryThemeClor" />
                </div>
                <div className={`z-10 absolute top-0 w-full bg-[#f8f8f8] rounded-md left-0 transform transition-all duration-300 origin-top ${showSideIcons ? 'scale-y-100 visible opacity-100' : 'scale-y-0 invisible opacity-0'}`}>
                    <div className='flex flex-col relative'>
                        <div className="flex justify-end p-2">
                            <button onClick={() => setShowSideIcons(prev => !prev)}
                                className="bg-secondaryThemeClor px-4 py-2 rounded-md text-themeBlack">
                                Close
                            </button>
                        </div>
                        {filteredCourse?.Courses?.map((ele, i) =>
                            <div
                                key={i}
                                onClick={() => {
                                    setCategoryName(ele?.category);
                                    setCategoryData(ele?.services);
                                    setShowSideIcons(false);
                                }}
                                className={`flex justify-between items-center ${categoryName === ele?.category ? "bg-primaryThemeColor transition-all duration-300" : "bg-[#f8f8f8]"} px-3 py-2 cursor-pointer gap-2 flex-shrink-0`}>
                                <span>{ele?.category}</span>
                                <span><IoIosArrowForward size={10} /></span>
                            </div>
                        )}
                    </div>
                </div>
                <div className='md-w-[350px] md:flex flex-col gap-2 hidden'>
                    <div className='flex flex-col gap-2 px-5'>
                        {filteredCourse?.Courses?.map((ele, i) =>
                            <div
                                key={i}
                                onClick={() => {
                                    setCategoryName(ele?.category);
                                    setCategoryData(ele?.services);
                                }}
                                className={`flex justify-between w-[230px] items-center ${categoryName === ele?.category ? "bg-primaryThemeColor transition-all duration-300" : "bg-[#f8f8f8]"} px-3 py-2 cursor-pointer rounded-md gap-2`}>
                                <span>{ele?.category}</span>
                                <span><IoIosArrowForward size={10} /></span>
                            </div>
                        )}
                    </div>
                </div>

                <div>
                    <div className="w-full pl-5 lg:pl-2 mb-2 tracking-wider text-secondaryThemeClor">
                        <h1 className="text-xl lg:text-2xl font-extrabold">
                            Topics Covered
                        </h1>
                    </div>

                    <div className='flex flex-wrap justify-center items-center gap-4'>
                        {categoryData?.map((v, i) => (
                            <ServiceCard
                                type='courses'
                                course={v}
                                key={i}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Courses;
