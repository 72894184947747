import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import img6 from '../../Assets/img-6.jpg';
import img7 from '../../Assets/img-7.jpg';
import img8 from '../../Assets/img-8.jpg';
import { GoLink } from "react-icons/go";

const RecentNews = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    const imgArr = [
        {
            id: 1,
            url: img6,
            title: "Exclusive Styling Sessions",
            content: "Our top stylists are offering exclusive one-on-one sessions to help you achieve impeccable style. These sessions will provide personalized consultations and styling tips tailored to your unique preferences. Book your session today and elevate your look with expert guidance."
        },
        {
            id: 2,
            url: img7,
            title: 'Launch of Signature Haircare Line',
            content: "We are proud to launch our new signature haircare line, designed to give your hair the impeccable care it deserves. This range includes shampoos, conditioners, and styling products formulated with premium ingredients to ensure your hair looks and feels its best. Available now at our salon."
        },
        {
            id: 3,
            url: img8,
            title: "Upcoming Style Workshops",
            content: "Join us for a series of style workshops where our experts will share the latest trends and techniques in hair styling and care. These interactive sessions are perfect for anyone looking to enhance their styling skills and stay ahead of fashion trends. Spaces are limited, so sign up soon!"
        }
    ];

    return (
        <div className="flex flex-col gap-4 py-10">
            <div className="flex justify-center">
                <span className="text-xl text-themeBlack tracking-wider border-gray-300 border-b-2 px-4 sm:px-8 py-4">
                    Recent News
                </span>
            </div>

            <div className="flex flex-wrap justify-center py-10 gap-6">
                {imgArr.map((v, i) => (
                    <div
                        key={i}
                        data-aos="fade-down-right"
                        className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
                        <div
                            style={{ backgroundImage: `url(${v.url})` }}
                            className="relative flex flex-col bg-cover justify-center items-center w-full h-60 px-4 group transition-all duration-300"
                        >
                            <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-50 transition-opacity duration-300"></div>
                            <div className="relative z-10">
                                <GoLink size={20} className="text-white" />
                            </div>
                        </div>
                        <div className="shadow-lg tracking-wide py-2 text-center">
                            {v.title}
                        </div>
                        <div className="text-justify px-4 w-full leading-5 py-2 tracking-wide text-sm">
                            {v.content}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RecentNews;
