import React from "react";
import "./NotFound.css"
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <section className="page_404">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 ">
              <div className="col-sm-10 col-sm-offset-1  text-center">
                <div className="four_zero_four_bg">
                  <h1 className="text-center ">404</h1>


                </div>

                <div className="contant_box_404">
                  <h3 className="h2">
                    Look like you're lost
                  </h3>

                  <p>the page you are looking for not avaible!</p>

                  <button
                    onClick={() => navigate('/')}
                    className='px-4 py-2 rounded-lg mt-4 font-bold tracking-wider bg-primaryThemeColor hover:bg-secondaryThemeClor'>
                    Go to Home
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default NotFound;