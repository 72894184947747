import React from 'react'
import Slider from '../../Components/Common/Slider'
import PortfolioImages from '../../Components/Common/PortfolioImages'
import CosmeticsInfo from '../../Components/Common/CosmeticsInfo'
import RecentNews from '../../Components/Common/RecentNews'
import Services from '../../Components/Common/Services'

const Home = () => {
  return (
    <React.Fragment>
      <Slider />
      <Services />
      <PortfolioImages />
      <CosmeticsInfo />
      <RecentNews />
    </React.Fragment>
  )
}

export default Home
