import Aos from 'aos';
import React from 'react';
import { useEffect } from 'react';
import { IoIosArrowForward } from "react-icons/io";

const About = () => {

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const team_members = [
    {
      "name": "Malaika",
      "role": "Barber",
      "desc": "Malaiks  brings precision and style to Jyoti's Professional Salon. With over a decade of experience, Alex is known for his impeccable cuts and attention to detail. Whether it's a classic shave or a modern hairstyle, Alex ensures every client leaves looking their best.",
      "img": "https://img.freepik.com/premium-photo/hair-care-happy-portrait-woman-with-tools-haircut-grooming-salon-treatment-smile-indian-young-model-girl-with-gear-hairstyle-isolated-studio-background_590464-207898.jpg?w=360"
    },
    {
      "name": "Simmi",
      "role": "Nail Artist",
      "desc": "Simmi, our talented nail artist at Jyoti's Professional Salon, specializes in creating stunning and unique nail designs. With a keen eye for detail and a passion for artistry, Sarah transforms nails into captivating works of art, ensuring every client leaves with beautiful, personalized nails.",
      "img": "https://www.ficcicascade.in/wp-content/uploads/2023/07/surprised-beautiful-woman-sits-table-with-makeup-tools-applying-tone-cream-spreading-hands-696x460.jpg"
    },
    {
      "name": "Yamuna",
      "role": "Make-up Specialist",
      "desc": "Yamuna, our skilled makeup specialist at Jyoti's Professional Salon, excels in enhancing natural beauty with her artistic touch. With a deep understanding of cosmetics and facial features, Emily creates stunning, personalized looks for every occasion, ensuring clients leave feeling confident and radiant.",
      "img": "https://www.ulikes.in/media_files/pages/1/415.jpg?1587091300"
    }
  ]

  return (
    <div className="px-4 sm:px-10 md:px-20">
      <div className="text-6xl text-center content-center font-semibold bg-[#f8f8f8] min-h-[200px] tracking-wider py-10">
        About us
      </div>

      <div className="flex gap-4 py-4 px-4 md:px-20 border-b border-gray-300 text-sm sm:text-base">
        <span>Home</span>
        <span><IoIosArrowForward size={20} className="text-secondaryThemeClor" /></span>
        <span>About us</span>
      </div>

      <div className="flex flex-col md:flex-row gap-4 px-4 md:px-20 mx-auto">
        <div className="flex flex-col py-5 w-full md:w-1/2 gap-4">
          <img
            src="https://w0.peakpx.com/wallpaper/615/983/HD-wallpaper-beauty-salon-beautician.jpg"
            alt="Image10"
            data-aos="fade-right"
            className="object-cover h-[300px] md:h-[450px] w-full" />
          <h1 className="text-xl font-semibold text-themeBlack tracking-wider">COMPANY HISTORY</h1>
          <p data-aos="fade-right" className="text-sm opacity-80">We started Jyoti's Professional 10 years ago, with a clear purpose to be the best in our region. Our humble beginnings now brought us to be the best salon ever.</p>
        </div>

        <div className="flex flex-col py-5 w-full md:w-1/2 gap-4">
          <img
            src="https://st3.depositphotos.com/12039412/16353/i/450/depositphotos_163531582-stock-photo-woman-in-beauty-salon-getting.jpg"
            alt="Image11"
            data-aos="fade-left"
            className="object-cover h-[300px] md:h-[450px] w-full" />
          <h1 className="text-xl font-semibold text-themeBlack tracking-wider">COMPANY MISSION</h1>
          <p data-aos="fade-left" className="text-sm opacity-80">Our mission is to inspire people to bring the best out of themselves. We offer fantastic glamour and the best treatment with affordable pricing.</p>
        </div>
      </div>

      <div className="flex flex-col sm:flex-row px-4 md:px-24 my-10 justify-between gap-4">
        {
          team_members?.map((data, i) => {
            return (
              <div
                key={i}
                data-aos="flip-left"
                className="border-b-2 border-gray-200 py-3 flex-1">
                <div className="group h-[300px] sm:h-[430px] overflow-hidden bg-[#f8f8f8] flex flex-col gap-2">
                  <img src={data.img}
                    alt="Image12"
                    className="w-full group-hover:-translate-y-16 h-full duration-500 object-cover" />
                  <p className="text-themeBlack opacity-80 tracking-wider px-2 leading-5 text-xs text-start group-hover:-translate-y-16 mt-1 duration-500">
                    {data?.desc}
                  </p>
                </div>
                <div className="flex flex-col items-center mt-5">
                  <h1 className="text-xl font-semibold tracking-wide text-themeBlack">
                    {data?.name}
                  </h1>
                  <p className="text-sm opacity-80 tracking-wider mt-2">
                    {data?.role}
                  </p>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default About
