import React from 'react'
import { HiOutlineScissors } from "react-icons/hi2";
import { FaEye } from "react-icons/fa6";
import { FaHandPointUp } from "react-icons/fa";
import { RiLeafFill } from "react-icons/ri";
import { useEffect } from 'react';
import Aos from 'aos';

const Services = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  const arr = [
    {
      id: 1,
      title: "Hair Styling",
      component: <HiOutlineScissors size={35} className='text-white' />,
      content: "Unlock your hair's full potential at our salon."
    },
    {
      id: 2,
      component: <FaEye size={35} className='text-white' />,
      title: "Eyelash Extensions",
      content: "Transform your look instantly with our eyelash extensions."
    },
    {
      id: 3,
      component: <FaHandPointUp size={30} className='text-white' />,
      title: "Nail Art",
      content: "Express yourself with our nail art services."
    },

    {
      id: 4,
      component: <RiLeafFill size={30} className='text-white' />,
      title: "Natural Hair Products",
      content: "Nourish your hair naturally with our products."
    },

  ]
  return (
    <div className='flex flex-col gap-4 '>
      <div className='flex justify-center '>
        <span className='text-xl text-themeBlack tracking wider border-gray-300 border-b-2 px-8 py-4'>
          Our Services
        </span>
      </div>

      <div className="flex flex-wrap gap-4 justify-center items-center mx-auto w-[90%] my-5">
        {arr?.map((v, i) => {
          return (
            <div
              data-aos="flip-left"
              key={i}
              className="flex flex-col gap-3 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 px-6 py-4 justify-center items-center"
            >
              <div className="border border-gray-400 p-4 rounded-full">
                <div className="bg-[#006287] rounded-full h-16 w-16 flex items-center justify-center">
                  {v?.component}
                </div>
              </div>
              <div className="content-center font-bold text-center">
                {v?.title}
              </div>
              <div className="text-themeBlack box-border tracking-wide text-center text-sm leading-7">
                {v?.content}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  )
}

export default Services
