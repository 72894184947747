import React, { useState, useRef } from 'react';
import he2 from '../../Assets/he2.png';
import emailjs from 'emailjs-com';
import Aos from 'aos';
import { useEffect } from 'react';

const Contact = () => {
  const initialState = {
    name: "",
    phone: "",
    email: "",
    message: "",
    nameErr: "",
    phoneErr: "",
    emailErr: "",
    messageErr: ""
  };

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false)
  const form = useRef();

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value, [`${name}Err`]: "" }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSendMessage = (e) => {
    e.preventDefault();
    const { name, phone, email, message } = state;
    let valid = true;

    if (!name) {
      setState((prevState) => ({ ...prevState, nameErr: "Name is required" }));
      valid = false;
    }
    if (!phone) {
      setState((prevState) => ({ ...prevState, phoneErr: "Phone is required" }));
      valid = false;
    }
    if (!email) {
      setState((prevState) => ({ ...prevState, emailErr: "Email is required" }));
      valid = false;
    }
    if (!message) {
      setState((prevState) => ({ ...prevState, messageErr: "Message is required" }));
      valid = false;
    }

    if (valid) {
      setLoading(true)
      emailjs.sendForm("service_tgeho0x", "template_qklqk8p", form.current, "DTnfVqRKtMaiidZWF")
        .then(
          (result) => {
            console.log(result.text);
            clearState();
            setLoading(false)
          },
          (error) => {
            console.log(error.text);
            setLoading(false)
          }
        );
    }
  };

  return (
    <div className="px-4 sm:px-10 md:px-20">
      <div>
        <img src={he2} alt="he2" className="w-full" />
      </div>

      <div data-aos="flip-down" className="flex flex-col md:flex-row gap-6 my-5 py-5">
        <div className="flex flex-col gap-4 w-full md:w-1/3">
          <h1 className="text-xl font-semibold tracking-wider text-themeBlack">CONTACT US</h1>
          <h3 className="text-md font-bold tracking-wider text-themeBlack">Jyoti's Professional</h3>
          <div className="text-sm text-justify text-themeBlack tracking-wider opacity-90 leading-6">
            <p>Mobile: +91-8093109311</p>
            <p>Mobile: +91-9090388382</p>
            <p>E-mail: jyotisprofessional54@gmail.com</p>
          </div>
        </div>

        <div data-aos="flip-down" className="flex flex-col gap-4 w-full md:w-1/3">
          <h1 className="text-xl font-semibold tracking-wider text-themeBlack">OUR ADDRESS</h1>
          <h3 className="text-md font-bold tracking-wider text-themeBlack">Jyoti's Professional</h3>
          <p className="text-themeBlack tracking-wider opacity-90 leading-7 text-sm">
            Reliance Smart,<br />
            First Floor, Kanika Chhak,<br />
            Cuttack - 753008
          </p>
        </div>

        <div data-aos="flip-down" className="flex flex-col gap-4 w-full md:w-1/3">
          <p className="text-themeBlack tracking-wider opacity-90 leading-5 text-sm text-justify">
            Feel free to talk to our representative at any time you please use our contact form on our website or one of our contact numbers. Brighten up your day with Jyoti's Professional Wordpress Theme.
          </p>
          <p className="text-themeBlack tracking-wider opacity-90 leading-7 text-sm text-justify">
            You can always visit us at any of our stores, we have a friendly staff and a mean cup of coffee.
          </p>
        </div>
      </div>

      <div>
        <h1 className="text-xl font-semibold text-themeBlack tracking-wider">SEND US A MESSAGE</h1>

        <form ref={form} onSubmit={handleSendMessage}>
          <div className="flex flex-col md:flex-row gap-5 my-5">
            <div className="flex flex-col gap-4 w-full md:w-1/3">
              <input
                type="text"
                placeholder="Name*"
                name='name'
                value={state.name}
                onChange={handleChange}
                className="text-[#132c4a] border px-3 py-3 placeholder-[#718092] bg-white rounded text-sm focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
              />
              {state.nameErr && <span className='text-red-400 text-xs '>{state.nameErr}</span>}
              <input
                type="text"
                placeholder="Phone*"
                name='phone'
                value={state.phone}
                onChange={handleChange}
                className="text-[#132c4a] border px-3 py-3 placeholder-[#718092] bg-white rounded text-sm focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
              />
              {state.phoneErr && <span className='text-red-400 text-xs '>{state.phoneErr}</span>}
              <input
                type="email"
                placeholder="Email*"
                name='email'
                value={state.email}
                onChange={handleChange}
                className="text-[#132c4a] border px-3 py-3 placeholder-[#718092] bg-white rounded text-sm focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
              />
              {state.emailErr && <span className='text-red-400 text-xs '>{state.emailErr}</span>}
            </div>
            <div className="flex-1 px-0 md:px-10">
              <textarea
                name="message"
                id="message"
                placeholder="Message*"
                rows="7"
                cols="50"
                value={state.message}
                onChange={handleChange}
                className="text-[#132c4a] border px-5 py-3 placeholder-[#718092] bg-white rounded text-sm focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
              ></textarea>
              {state.messageErr && <span className='text-red-400 text-xs '>{state.messageErr}</span>}

              <div className="flex justify-end mt-5">
                <button
                  disabled={loading}
                  className="text-lg font-semibold w-48 py-2 rounded-md text-themeBlack bg-btnHoverColor hover:bg-primaryThemeColor duration-500">
                  {loading ? "...LOADING" : "SEND MESSAGE"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Contact;
