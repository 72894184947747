import React from 'react';
import { FaStopwatch } from "react-icons/fa6";
import { BiSolidPhoneCall } from "react-icons/bi";
import logo from "../../Assets/jps-logo.png";
import { useNavigate } from 'react-router-dom';
import SideBar from './SideBar';

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <nav className="bg-white text-white mb-2">
      <div className="max-w-full mx-10 px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-2 md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <div className='text-3xl text-themeBlack tracking-wide'>
              <img src={logo} alt="LOGO" height={100} width={100} />
            </div>
          </div>
          <div className='flex flex-col px-4 justify-center gap-4'>
            <div className='md:flex justify-end text-themeBlack border-[themeBlack] border-b-2 py-4 hidden'>
              <div className='flex justify-between gap-6'>
                <div className='flex gap-2 items-center'>
                  <FaStopwatch size={22} color='#FFD700' />
                  <span className='tracking-wider'>
                    Open :
                    <span className='text-sm tracking-wide'>
                      10:00 Am to 10:00 Pm
                    </span>
                  </span>
                </div>
                <div className='flex gap-2 items-center'>
                  <BiSolidPhoneCall size={22} color='#FFD700' />
                  <span className='tracking-wider'>
                    <span className='text-sm tracking-wide'>
                      +91-8093109311, +91-9090388382
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="hidden md:flex space-x-10">
              <div
                onClick={() => navigate("/")}
                className="cursor-pointer  font-medium text-themeBlack hover:text-secondaryThemeClor duration-300 tracking-wide">
                HOME
              </div>

              <div className="cursor-pointer group relative inline-block font-medium text-themeBlack hover:text-secondaryThemeClor duration-300 tracking-wide">
                SERVICES
                <div className="absolute z-10 top-full left-0 bg-white flex flex-col w-[200px] rounded-b-md opacity-0 invisible group-hover:visible group-hover:opacity-100 transition-all duration-300 transform origin-top scale-y-0 group-hover:scale-y-100">
                  <div
                    onClick={() => navigate("/services/male_services")}
                    className='text-themeBlack cursor-pointer px-3 py-2 hover:bg-primaryThemeColor text-start duration-300'>
                    Male Services
                  </div>
                  <div
                    onClick={() => navigate("/services/female_services")}
                    className='text-themeBlack cursor-pointer px-3 py-2 hover:bg-primaryThemeColor text-start duration-300'>
                    Female Services
                  </div>
                </div>
              </div>

              <div className="cursor-pointer group relative inline-block font-medium text-themeBlack hover:text-secondaryThemeClor duration-300 tracking-wide">
                COURSES
                <div className="absolute z-10 top-full left-0 bg-white flex flex-col w-[200px] rounded-b-md opacity-0 invisible group-hover:visible group-hover:opacity-100 transition-all duration-300 transform origin-top scale-y-0 group-hover:scale-y-100">
                  <div
                    onClick={() => navigate("/courses/Basic Course")}
                    className='text-themeBlack cursor-pointer px-3 py-2 hover:bg-primaryThemeColor text-start duration-300'>
                    Basic Course
                  </div>
                  <div
                    onClick={() => navigate("/courses/Diploma Course")}
                    className='text-themeBlack cursor-pointer px-3 py-2 hover:bg-primaryThemeColor text-start duration-300'>
                    Diploma Course
                  </div>
                  <div
                    onClick={() => navigate("/courses/Makeup detailed course")}
                    className='text-themeBlack cursor-pointer px-3 py-2 hover:bg-primaryThemeColor text-start duration-300'>
                    Makeup Course
                  </div>
                  <div
                    onClick={() => navigate("/courses/Master Course")}
                    className='text-themeBlack cursor-pointer px-3 py-2 hover:bg-primaryThemeColor text-start duration-300'>
                    Master Course
                  </div>
                </div>
              </div>

              <div
                onClick={() => navigate("/about")}
                className=" font-medium text-themeBlack cursor-pointer hover:text-secondaryThemeClor duration-300 tracking-wide">
                ABOUT US
              </div>

              <div
                onClick={() => navigate("/contact")}
                className="text-XL font-medium cursor-pointer text-themeBlack hover:text-secondaryThemeClor duration-300 tracking-wide">
                CONTACT US
              </div>
            </div>
          </div>
          <div className='bg-[#FFD700] rounded-md content-center block md:hidden'>
            <SideBar />
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
