import React from 'react'
import { IoLocationSharp } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdPhonePortrait } from "react-icons/io";
import { FaFax } from "react-icons/fa";
import Aos from 'aos';
import { useEffect } from 'react';
import { MdEmail } from "react-icons/md";

const Footer = () => {
    const openTime = [
        ["Monday", "10 am - 10 pm"],
        ["Tuesday", "10 am - 10 pm"],
        ["Wednesday", "10 am - 10 pm"],
        ["Thursday", "10 am - 10 pm"],
        ["Friday", "10 am - 10 pm"],
        ["Saturday", "10 am - 10 pm"],
        ["Sunday", "10 am - 10 pm"],
    ]

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <React.Fragment>
            <footer
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="1500"
                className="footer bg-themeBlack shadow text-white py-14 px-4 sm:px-10 md:px-20 lg:px-28">
                <div className="container mx-auto tracking-wider">
                    <div className="flex flex-col md:flex-row justify-between gap-10">
                        <div className="flex flex-col w-full md:w-1/4 gap-7">
                            <span className="uppercase text-xl font-semibold tracking-wider text-themeWhite">
                                Jyoti's Professional
                            </span>
                            <span className="text-sm text-themeWhite text-justify leading-7">
                                Welcome to Jyoti's Professional, where beauty is more than skin deep. Discover beauty redefined at Jyoti's Professional Salon. Our expert team offers tailored services to enhance your natural beauty and boost your confidence. Experience luxury and expertise in every visit.
                            </span>
                        </div>

                        <div className="flex flex-col w-full md:w-1/4 gap-6">
                            <span className="uppercase text-xl font-semibold tracking-wider text-themeWhite">
                                CONTACT
                            </span>
                            <div className="flex gap-4 items-start">
                                <FaPhoneAlt size={15} />
                                <span className="text-sm">+91-8093109311</span>
                            </div>
                            <div className="flex gap-4 items-start">
                                <FaPhoneAlt size={15} />
                                <span className="text-sm">+91-9090388382</span>
                            </div>
                            <div className="flex gap-4 items-start">
                                <MdEmail size={15} />
                                <span className="text-sm">
                                    jyotisprofessional54@gmail.com
                                </span>
                            </div>

                            <div className="flex gap-4 items-start">
                                <IoLocationSharp size={15} />
                                <div className="flex flex-col gap-1 text-sm">
                                    Reliance Smart,<br />
                                    First Floor, Kanika Chhak,<br />
                                    Cuttack - 753008
                                </div>
                            </div>
                        </div>

                        <div className="w-full md:w-1/4">
                            <div className="border-4 border-gray-400 flex justify-center p-2">
                                <div className="border-2 border-gray-400 flex flex-col gap-2 p-2 text-sm w-full">
                                    <h1 className="text-themeWhite font-semibold text-xl">
                                        OPENING TIME
                                    </h1>
                                    {openTime.map(([day, time], index) => (
                                        <span key={index} className="flex justify-between">
                                            <span>{day}</span>
                                            <span className={time === "CLOSE" ? "text-blue-500" : ""}>{time}</span>
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-t pt-2 flex flex-col md:flex-row justify-between mt-5 gap-4 md:gap-0">
                        <div className="w-full md:w-1/2 text-center md:text-left mb-4 md:mb-0">
                            {new Date().getFullYear()} © <span>Jyoti's Professional</span>.
                        </div>
                        <div className="w-full md:w-1/2 text-center md:text-right">
                            <div className="text-sm">
                                Design & Develop by
                                <a href="#" className="ms-1 underline">
                                    Web-Bocket
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    )
}

export default Footer
