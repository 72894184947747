import Aos from 'aos';
import React, { useEffect } from 'react'
import { FaIndianRupeeSign } from "react-icons/fa6";
import coursesImg from "../../Assets/saloonillu.png";

const ServiceCard = ({ value, course, type }) => {
    const imgss = "https://www.therapyhairstudio.com/wp-content/uploads/2022/10/man-getting-haircut-in-salon.jpg";

    return (
        <div
            data-aos="flip-left"
            className="w-full md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
            <div className="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
                <figure className="mb-2">
                    <img
                        src={type === "courses" ? course?.img_url : value?.img_url}
                        alt="cards-img"
                        className="h-auto ml-auto mr-auto rounded-md" />
                </figure>

                <div className="tracking-wider rounded-lg p-4 bg-gradient-to-tr from-[#c232a9] to-[#ff0] flex flex-col text-center">
                    <div className="text-themeBlack text-sm font-semibold">
                        {value?.name}
                    </div>

                    <div className="flex items-center justify-center text-lg text-themeWhite font-bold">
                        {value?.price && <FaIndianRupeeSign />}{value?.price}
                    </div>
                    <div className="flex items-center justify-center text-lg text-themeWhite font-bold">
                        {course && course?.name}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceCard